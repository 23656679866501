import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti';

const ResultsPage = ({ results, resetVoting }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="card">
      {loading ? (
        <div>
          <h2>Calculating the winner...</h2>
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <Confetti />
          <h2 className="gold">Winner: {results[0]}</h2>
          <ul>
            {results.slice(1).map((result, index) => (
              <li key={index}>{index + 2}. {result}</li>
            ))}
          </ul>
          <button className="restart-button" onClick={resetVoting}>Restart Voting</button>
        </>
      )}
    </div>
  );
};

export default ResultsPage;
