import React, { useState } from 'react';

const HomePage = ({ startVoting }) => {
  const [numVoters, setNumVoters] = useState(2);
  const [options, setOptions] = useState("");

  const handleStartVoting = () => {
    const cleanedOptions = options
      .split(/[,|\n]/)
      .map(option => option.replace(/\band\b|\bor\b/g, '').trim())
      .filter(option => option);
    startVoting(cleanedOptions, numVoters);
  };

  const handleEnterPress = (event) => {
    if (event.key === 'Enter') {
      handleStartVoting();
    }
  };

  return (
    <div className="card">
      <h1>Welcome to 2Choice.Us</h1>
      <label>
        Number of voters:
        <input
          type="number"
          value={numVoters}
          onChange={(e) => setNumVoters(e.target.value)}
          onKeyPress={handleEnterPress}
        />
      </label>
      <label>
        Enter the options separated by commas or lines:
        <textarea
          value={options}
          onChange={(e) => setOptions(e.target.value)}
          onKeyPress={handleEnterPress}
        />
      </label>
      <button onClick={handleStartVoting}>Start Voting</button>
    </div>
  );
};

export default HomePage;
