import React, { useState, useEffect } from 'react';
import HomePage from './HomePage';
import VotingPage from './VotingPage';
import ResultsPage from './ResultsPage';

const App = () => {
  const [step, setStep] = useState('home');
  const [options, setOptions] = useState([]);
  const [votes, setVotes] = useState([]);
  const [currentVoter, setCurrentVoter] = useState(1);
  const [numVoters, setNumVoters] = useState(1);
  const [summaries, setSummaries] = useState([]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      const confirmationMessage = "Are you sure you want to leave? Your progress will be lost.";
      e.returnValue = confirmationMessage;
      return confirmationMessage;
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  const startVoting = (optionsArray, numVoters) => {
    setOptions(optionsArray);
    setNumVoters(numVoters);
    setCurrentVoter(1);
    setVotes([]);
    setSummaries([]);
    setStep('voting');
  };

  const submitVotes = (userVotes) => {
    const newVotes = [...votes, { user: currentVoter, votes: userVotes }];
    setVotes(newVotes);
    setSummaries([...summaries, { user: currentVoter, votes: userVotes }]);
    if (currentVoter < numVoters) {
      setCurrentVoter(currentVoter + 1);
    } else {
      setStep('results');
    }
  };

  const calculateResults = () => {
    const voteCounts = {};
    votes.forEach((voteObj) => {
      voteObj.votes.forEach((vote) => {
        voteCounts[vote] = (voteCounts[vote] || 0) + 1;
      });
    });

    const rankedResults = Object.keys(voteCounts)
      .sort((a, b) => voteCounts[b] - voteCounts[a]);

    return rankedResults;
  };

  const resetVoting = () => {
    if (window.confirm("Are you sure you want to restart voting? All progress will be lost.")) {
      setOptions([]);
      setVotes([]);
      setCurrentVoter(1);
      setNumVoters(1);
      setSummaries([]);
      setStep('home');
    }
  };

  const nextVoter = () => {
    if (currentVoter < numVoters) {
      setStep('voting');
    } else {
      setStep('results');
    }
  };

  return (
    <div className="app-container">
      {step === 'home' && <HomePage startVoting={startVoting} />}
      {step === 'voting' && (
        <VotingPage
          key={`voter-${currentVoter}`}
          options={options}
          submitVotes={submitVotes}
          currentVoter={currentVoter}
          showSummary={currentVoter <= numVoters}
          summary={summaries[currentVoter - 1] || null}
          nextVoter={nextVoter}
        />
      )}
      {step === 'results' && <ResultsPage results={calculateResults()} resetVoting={resetVoting} />}
    </div>
  );
};

export default App;
