import React, { useState, useEffect } from 'react';

const VotingPage = ({ options, submitVotes, currentVoter }) => {
  const [pairwiseOptions, setPairwiseOptions] = useState([]);
  const [votes, setVotes] = useState([]);
  const [currentPairIndex, setCurrentPairIndex] = useState(0);
  const [showSummary, setShowSummary] = useState(false);

  useEffect(() => {
    const pairs = [];
    for (let i = 0; i < options.length; i++) {
      for (let j = i + 1; j < options.length; j++) {
        pairs.push([options[i], options[j]]);
      }
    }
    setPairwiseOptions(pairs);
  }, [options]);

  const handleVote = (choice) => {
    setVotes((prevVotes) => [...prevVotes, choice]);
    if (currentPairIndex < pairwiseOptions.length - 1) {
      setCurrentPairIndex(currentPairIndex + 1);
    } else {
      setShowSummary(true);
    }
  };

  const handleContinue = () => {
    submitVotes(votes);
    setShowSummary(false);
  };

  const rankVotes = () => {
    const counts = options.reduce((acc, option) => ({ ...acc, [option]: 0 }), {});
    votes.forEach(vote => {
      counts[vote] = (counts[vote] || 0) + 1;
    });
    return Object.entries(counts)
      .sort((a, b) => b[1] - a[1])
      .map(([option]) => option);
  };

  const currentPair = pairwiseOptions[currentPairIndex] || [];
  const rankedVotes = rankVotes();

  return (
    <div className="card">
      {showSummary ? (
        <div>
          <h2>Summary for User {currentVoter}</h2>
          <ul>
            {rankedVotes.map((vote, index) => (
              <li key={index}>{index + 1}. {vote}</li>
            ))}
          </ul>
          <button onClick={handleContinue}>Continue</button>
        </div>
      ) : (
        <div>
          <h1>Choose Your Preferences - User {currentVoter}</h1>
          <p>Which do you prefer?</p>
          {currentPair.length === 2 && (
            <>
              <button className={`voter-button-${currentVoter}`} onClick={() => handleVote(currentPair[0])}>{currentPair[0]}</button>
              <span style={{ margin: '0 10px' }}>or</span>
              <button className={`voter-button-${currentVoter}`} onClick={() => handleVote(currentPair[1])}>{currentPair[1]}</button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default VotingPage;
